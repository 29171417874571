<template>
  <mi-layout
    class="full-height"
    :class="$route.meta?.staticWidth && 'mi-app-wrapper'"
  >
    <!-- Application header -->
    <app-header v-if="$route.meta.isAppHeaderVisible"></app-header>

    <!-- Page content -->
    <mi-page-container class="full-height">
      <mi-page class="full-height">
        <router-view></router-view>
      </mi-page>
    </mi-page-container>
  </mi-layout>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import { Hub } from 'aws-amplify'

  import iconMapFn from '@/plugins/quasar/iconMapFn'
  import getUserSession from '@/utils/getUserSession'
  import notify from '@/utils/notify'

  import { SET_USER } from '@/store/mutationTypes'
  import { SELECT_PRODUCT_MODEL } from '@/store/modules/product-model/mutationTypes'

  import AppHeader from '@/components/AppHeader.vue'

  import handleCustomEvents from '@/utils/custom-events/customEventsHandler'

  export default {
    name: 'App',
    components: {
      AppHeader
    },
    data: () => ({
      isAppRefreshing: false,
      registration: null,
      currentSWState: ''
    }),
    computed: {
      isRouteAuthenticated() {
        return this.$route?.isAuthRequired
      },
      ...mapGetters('product-model', ['selectedProductModel'])
    },
    async created() {
      Hub.listen('auth', this.handleHubAuthEventUpdate)
      // Provide custom icons library to Quasar
      this.$q.iconMapFn = iconMapFn
      handleCustomEvents()
    },
    async mounted() {
      if ('serviceWorker' in navigator) {
        this.currentSWState = (await navigator.serviceWorker.getRegistration())?.active?.state

        document.addEventListener('swUpdated', this.handleSwUpdatedEvent)
        navigator.serviceWorker.addEventListener('controllerchange', this.reloadApp)
      }
      // Refreshes user info
      const userData = await getUserSession()
      this.updateUserData(userData)
      if (this.isRouteAuthenticated) {
        this.getProductModelsList()
        let savedEncodedBusinessName = await this.getCurrentSelection()
        if (!savedEncodedBusinessName) {
          savedEncodedBusinessName = this.$q.localStorage.getItem(process.env.VUE_APP_STORAGE_KEY_PRODUCT_MODEL) || {}
        }
        this.changeProductModel(savedEncodedBusinessName)
      }
    },
    methods: {
      ...mapActions('product-model', ['getProductModelsList']),
      ...mapMutations({ SET_USER }),
      ...mapMutations('product-model', { SELECT_PRODUCT_MODEL }),
      ...mapActions('search', ['getCurrentSelection']),
      changeProductModel(encodedBusinessName) {
        this.SELECT_PRODUCT_MODEL(encodedBusinessName)
        const { id, productModelType } = this.selectedProductModel
        this.$q.localStorage.set(process.env.VUE_APP_STORAGE_KEY_PRODUCT_MODEL, {
          id,
          encodedBusinessName,
          productModelType
        })
      },
      async reloadApp() {
        if (this.isAppRefreshing) return

        const { state: newSWState } = (await navigator.serviceWorker.getRegistration())?.active || {}

        if (this.currentSWState === 'activated' && newSWState === 'activating') {
          this.isAppRefreshing = true
          window.location.reload()
        }
      },
      applyNewAppVersion() {
        this.registration?.waiting?.postMessage('skipWaiting')
      },
      handleHubAuthEventUpdate({ payload: { event = '' } } = {}) {
        switch (event) {
          case 'signIn':
          case 'cognitoHostedUI':
            getUserSession()
              .then(this.updateUserData)
            break
          case 'signOut':
            this.updateUserData()
            break
          default:
            break
        }
      },
      handleSwUpdatedEvent({ detail } = {}) {
        this.registration = detail

        notify({
          title: 'New version available',
          content: 'Click the button below to apply a new version.',
          actions: [{
            label: 'Update',
            color: 'accent',
            handler: this.applyNewAppVersion
          }],
          progress: false
        })
      },
      updateUserData({
        token = '',
        user
      } = {}) {
        if (token) {
          this.$q.localStorage.set(process.env.VUE_APP_STORAGE_KEY_TOKEN, token)
        }
        else {
          this.$q.localStorage.remove(process.env.VUE_APP_STORAGE_KEY_TOKEN)
        }

        this.SET_USER(user)
      }
    }
  }
</script>

<style>
  html {
    scroll-behavior: smooth;
  }
</style>
