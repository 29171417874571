export const PRODUCT_MODEL_BOM_VALIDATION = '/:productModelId/bom-validation'
export const PRODUCT_MODEL_LIST = '/productmodels/list'
export const PRODUCT_MODEL_LOAD = '/productmodels/load/:pmEncodedBusinessName'
export const PRODUCT_MODEL_DETECT_ELEMENTS_CHANGES = '/productmodels/detect-element-changes'
export const VALIDATION_SETTINGS_GET = '/settings/:pmEncodedBusinessName'
export const PRODUCT_MODEL_SETTINGS_SAVE = '/settings'
export const PRODUCT_MODEL_COUNTS = '/bom-validation/'
  + 'status?productModelIds={productModelIds}&encodedBusinessNames={encodedBusinessNames}'
export const PRODUCT_MODEL_DOWNLOAD_COMPLETE = '/export/'
  + 'bom-validation?productModelIds={productModelIds}'
export const PRODUCT_MODEL_COMPARE_DATES = '/unusable-choices/execution-dates'
export const PRODUCT_MODAL_COMPARE_POST = '/unusable-choices/comparison'
