import { vmHttpClient } from '@/api/instances'
import { SUMMARY, SUMMARY_LIST, SUMMARY_SELECTION } from './endpoints'

// DELETE: Summary
export const deleteSummary = async (id = '') => vmHttpClient({
  url: SUMMARY,
  method: 'DELETE',
  params: { id }
})

// GET: Summary
export const getSummary = async (id = '') => vmHttpClient({
  url: SUMMARY,
  method: 'GET',
  params: { id }
})

// GET: Summary selection
export const getSummarySelection = async () => vmHttpClient({
  url: SUMMARY_SELECTION,
  method: 'GET'
})

// GET: Summaries
export const getSummaries = async () => vmHttpClient({
  url: SUMMARY_LIST,
  method: 'GET'
})

// POST: Summary
export const postSummary = async ({
  name = '',
  pmEncodedBusinessName = '',
  options = [],
  components = [],
  currentSelection = false
} = {}) => vmHttpClient({
  url: SUMMARY,
  method: 'POST',
  data: {
    name,
    pmEncodedBusinessName,
    options,
    components,
    currentSelection
  }
})
