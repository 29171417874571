import { feedbackHttpClient } from '@/api/instances'
import { NEW_MISSING_FEATURE_FEEDBACK } from './endpoints'

// POST: Create Feedback
export const createMissingFeatureFeedback = async ({ selectedOptions }) => feedbackHttpClient({
  url: NEW_MISSING_FEATURE_FEEDBACK,
  method: 'POST',
  data: {
    selectedOptions
  }
})
