import showHTTPErrorNotification from '@/api/errors-handler'

// Response data handling
export function responseSuccess(response = {}) {
  return response.data || {}
}

// Response error handling
export function responseError(error = {}) {
  const { status = '', data = '' } = error.response || {}
  const notificationParams = { status }

  if (data) {
    if (typeof data === 'string') {
      notificationParams.message = data
    }
    else if (typeof data.message === 'string') {
      notificationParams.message = data.message
    }
  }

  showHTTPErrorNotification(notificationParams)

  return Promise.reject(error)
}

// Polling response error handling
export function responsePollingError(ignoreStatuses, error = {}) {
  const { status = '', data = '' } = error.response || {}
  const notificationParams = { status }

  if (data && typeof data === 'string') {
    notificationParams.message = data
  }

  if (status === '' || !ignoreStatuses.includes(status)) {
    showHTTPErrorNotification(notificationParams)
  }

  return Promise.reject(error)
}
