import axios from 'axios'
import { requestSuccess, requestError, responseSuccess, responseError, responsePollingError } from '../interceptors'

export function createInstance({ url = '/', headers = {}, interceptors = true } = {}) {
  const instance = axios.create({
    baseURL: `${ process.env.VUE_APP_DOMAIN_URL }${ url }`,
    headers
  })

  if (interceptors) {
    instance.interceptors.request.use(requestSuccess, requestError)
    instance.interceptors.response.use(responseSuccess, responseError)
  }

  return instance
}

export function createHttpClientPollingInstance({ url, ignoreStatuses = [] }) {
  const client = createInstance({
    url,
    interceptors: false
  })

  client.interceptors.request.use(requestSuccess, requestError)
  client.interceptors.response.use(responseSuccess, (error = {}) => responsePollingError(
    ignoreStatuses, error
  ))
  return client
}
