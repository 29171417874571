export const CONFIGURATION = '/configuration'
export const CONFIGURATION_C4S = '/configuration/c4s'
export const CONFIGURATION_COMBINATIONS = '/configuration/combinations/v2'
export const CONFIGURATION_COMBINATIONS_V2_DATA = '/configuration/combinations/v2/correlation-id'
export const CONFIGURATION_EXPLANATION = '/configuration/explanation/v2'
export const CONFIGURATION_EXPLANATION_DATA = '/configuration/explanation'
export const CONFIGURATION_EXPORT_GREEN = '/configuration/export-green/v2'
export const CONFIGURATION_EXPORT_YELLOW = '/configuration/export-yellow'
export const CONFIGURATION_EXPORT_WINDCHILL = '/windchill'
export const CONFIGURATION_EXPORT_WINDCHILL_VARIANT_SPEC_DATA = '/windchill/variant-specification'
export const CONFIGURATION_EXPORT_WINDCHILL_VARIANT_SPEC = '/windchill/variant-specification/v2'
export const CONFIGURATION_EXPORT_WINDCHILL_FILTER = 'windchill/filters/v2'
export const CONFIGURATION_EXPORT_WINDCHILL_FILTER_DATA = 'windchill/filters'
export const CONFIGURATION_FILL_ALL_VALID_CHOICES = '/configuration/auto-fill/all-valid-choices/v2'
export const CONFIGURATION_FILL_COMPLETE = '/configuration/auto-fill/complete/v2'
export const CONFIGURATION_FILL_SINGLE_VALID_CHOICES = '/configuration/auto-fill/single-valid-choices/v2'
export const CONFIGURATION_FILL_SINGLE_VALID_CHOICES_DATA = '/configuration/auto-fill/choices/choice-id'
export const CONFIGURATION_UPDATE = '/configuration/update'
export const CONFIGURATION_VALIDATION = '/configuration/validation'
export const CONFIGURATION_VALIDATION_V2 = '/configuration/validation/v2'
export const CONFIGURATION_WINDCHILL_CONFIG_SPECS = '/windchill/configuration-specifications'
export const CONFIGURATION_WINDCHILL_CONTAINERS = '/windchill/containers'
export const CONFIGURATION_WINDCHILL_OPTION_SETS = '/windchill/option-sets'
