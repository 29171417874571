import { pmmHttpClient } from '@/api/instances'
import { CHOICE_SEARCH } from './endpoints'

// GET: Search choices
export const searchChoices = async ({
  pmType = '',
  pmIdentifier = '',
  numberOfElements = -1,
  query = ''
} = {}) => pmmHttpClient({
  url: CHOICE_SEARCH,
  method: 'GET',
  params: {
    pmType,
    pmIdentifier,
    numberOfElements,
    query
  }
})
