const awsConfig = {
  Auth: {
    region: process.env.VUE_APP_AWS_REGION,
    userPoolId: process.env.VUE_APP_USER_POOL_ID,
    identityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_USER_POOL_CLIENT_ID,
    oauth: {
      domain: process.env.VUE_APP_OAUTH_DOMAIN,
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: process.env.VUE_APP_OAUTH_REDIRECT_URL,
      redirectSignOut: process.env.VUE_APP_OAUTH_REDIRECT_URL,
      responseType: 'code'
    }
  },
  Analytics: {
    autoSessionRecord: true,
    AWSPinpoint: {
      appId: process.env.VUE_APP_AWS_APP_ID,
      region: process.env.VUE_APP_AWS_REGION,
      mandatorySignIn: false
    }
  }
}

export default awsConfig
