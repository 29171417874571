import { pmmHttpClient } from '@/api/instances'
import { LIST_CATEGORIES, LIST_COMPONENTS, LIST_HIGH_GROUPS, LIST_OPTIONS } from '@/api/rest/browse-portfolio/endpoints'

export const getCategories = async (pmEncodedBusinessName = '') => pmmHttpClient({
  url: LIST_CATEGORIES + pmEncodedBusinessName,
  method: 'GET'
})
export const getOptions = async (id, pmEncodedBusinessName = '') => pmmHttpClient({
  url: LIST_OPTIONS.replace(':id', id) + pmEncodedBusinessName,
  method: 'GET'
})

export const getHighGroups = async (pmEncodedBusinessName = '') => pmmHttpClient({
  url: LIST_HIGH_GROUPS + pmEncodedBusinessName,
  method: 'GET'
})

export const getComponents = async (id, pmEncodedBusinessName = '') => pmmHttpClient({
  url: LIST_COMPONENTS.replace(':id', id) + pmEncodedBusinessName,
  method: 'GET'
})
