import { notificationsHttpClient } from '@/api/instances'
import { NOTIFICATIONS_STATE } from '@/api/rest/notifications/endpoints'

export const getNotificationState = async ({ type, seen } = {}) => notificationsHttpClient({
  url: NOTIFICATIONS_STATE,
  method: 'GET',
  params: {
    type,
    seen
  }
})
