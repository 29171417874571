import { pacHttpClient, pacHttpClientPolling } from '@/api/instances'
import {
  CONFIGURATION,
  CONFIGURATION_C4S,
  CONFIGURATION_COMBINATIONS,
  CONFIGURATION_EXPLANATION,
  CONFIGURATION_EXPORT_GREEN,
  CONFIGURATION_EXPORT_YELLOW,
  CONFIGURATION_EXPORT_WINDCHILL,
  CONFIGURATION_EXPORT_WINDCHILL_VARIANT_SPEC,
  CONFIGURATION_EXPORT_WINDCHILL_FILTER,
  CONFIGURATION_EXPORT_WINDCHILL_FILTER_DATA,
  CONFIGURATION_FILL_ALL_VALID_CHOICES,
  CONFIGURATION_FILL_COMPLETE,
  CONFIGURATION_FILL_SINGLE_VALID_CHOICES,
  CONFIGURATION_UPDATE,
  CONFIGURATION_VALIDATION,
  CONFIGURATION_VALIDATION_V2,
  CONFIGURATION_WINDCHILL_CONFIG_SPECS,
  CONFIGURATION_WINDCHILL_CONTAINERS,
  CONFIGURATION_WINDCHILL_OPTION_SETS,
  CONFIGURATION_FILL_SINGLE_VALID_CHOICES_DATA,
  CONFIGURATION_COMBINATIONS_V2_DATA, CONFIGURATION_EXPLANATION_DATA, CONFIGURATION_EXPORT_WINDCHILL_VARIANT_SPEC_DATA
} from '@/api/rest/pac/endpoints'

// DELETE: Configuration
export const deleteConfiguration = async (id = '') => pacHttpClient({
  url: `${ CONFIGURATION }/${ id }`,
  method: 'DELETE'
})

// GET: Configuration
export const getConfiguration = async (id = '') => pacHttpClient({
  url: `${ CONFIGURATION }/${ id }`,
  method: 'GET'
})

// GET: Configurations
export const getConfigurations = async params => pacHttpClient({
  url: CONFIGURATION,
  method: 'GET',
  params: new URLSearchParams(params)
})

// POST: Configuration
export const createConfiguration = async ({
  c4sCode = '',
  description = '',
  name = '',
  planningPeriodRange = {},
  productModel = {},
  project = '',
  authorizedUsers = []
} = {}) => pacHttpClient({
  url: CONFIGURATION,
  method: 'POST',
  data: {
    c4sCode,
    description,
    name,
    planningPeriodRange,
    productModel,
    project,
    selectedChoices: [],
    authorizedUsers
  }
})

// POST: Configuration
export const createFullConfiguration = async ({
  c4sCode = '',
  creator = '',
  description = '',
  ignoreList = [],
  invalidChoices = [],
  modifiedDate = '',
  name = '',
  planningPeriodRange = {},
  productModel = {},
  project = '',
  selectedChoices = [],
  windChillData = {},
  authorizedUsers = []
} = {}) => pacHttpClient({
  url: CONFIGURATION,
  method: 'POST',
  data: {
    c4sCode,
    creator,
    description,
    ignoreList,
    invalidChoices,
    modifiedDate,
    name,
    planningPeriodRange,
    productModel,
    project,
    selectedChoices,
    windChillData,
    authorizedUsers
  }
})

// POST: C4S configuration
export const createC4SConfiguration = async ({
  file = {},
  data = {
    c4sCode: '',
    description: '',
    name: '',
    planningPeriodRange: {},
    productModel: {},
    project: '',
    selectedChoices: [],
    windChillData: {}
  }
} = {}) => {
  const formData = new FormData()

  formData.append('data', new Blob([JSON.stringify(data)], { type: 'application/json' }))
  formData.append('file', file)

  return pacHttpClient({
    url: CONFIGURATION_C4S,
    method: 'POST',
    data: formData
  })
}

// POST: Configuration combinations
export const getConfigurationCombinations = async ({
  planningPeriodRange = {},
  reference = '',
  relevantDomains = [],
  rulesToIgnore = [],
  selectedChoices = []
} = {}) => pacHttpClient({
  url: CONFIGURATION_COMBINATIONS,
  method: 'POST',
  data: {
    planningPeriodRange,
    reference,
    relevantDomains,
    rulesToIgnore,
    selectedChoices
  }
})

// POST: Configuration explanation V2
export const requestConfigurationExplanation = async ({
  c4sCode = '',
  createdDate = '',
  creator = '',
  description = '',
  id = '',
  ignoreList = [],
  invalidChoices = [],
  modifiedDate = '',
  name = '',
  planningPeriodRange = {},
  productModel = {},
  project = '',
  selectedChoices = []
} = {}) => pacHttpClient({
  url: CONFIGURATION_EXPLANATION,
  method: 'POST',
  data: {
    c4sCode,
    createdDate,
    creator,
    description,
    id,
    ignoreList,
    invalidChoices,
    modifiedDate,
    name,
    planningPeriodRange,
    productModel,
    project,
    selectedChoices
  }
})

export const getConfigurationExplanation = async (correlationId = '') => pacHttpClientPolling({
  url: `${ CONFIGURATION_EXPLANATION_DATA }/${ correlationId }`,
  method: 'GET'
})

export const getGreenConfiguration = async (id = '') => pacHttpClientPolling({
  url: `${ CONFIGURATION_EXPORT_GREEN }/${ id }`,
  method: 'GET'
})

// POST: Export green configuration
export const exportGreenConfiguration = async ({
  c4sCode = '',
  description = '',
  exportDate = '',
  ignoreList = [],
  name = '',
  planningPeriodRange = {},
  productModelName = '',
  project = '',
  selectedChoices = [],
  validationStatus = ''
} = {}) => pacHttpClient({
  url: CONFIGURATION_EXPORT_GREEN,
  method: 'POST',
  data: {
    c4sCode,
    description,
    exportDate,
    ignoreList,
    name,
    planningPeriodRange,
    productModelName,
    project,
    selectedChoices,
    validationStatus
  }
})

// POST: Export yellow configuration
export const exportYellowConfiguration = async ({
  c4sCode = '',
  description = '',
  exportDate = '',
  ignoreList = [],
  name = '',
  planningPeriodRange = {},
  productModelName = '',
  project = '',
  selectedElements = [],
  validationStatus = ''
} = {}) => pacHttpClient({
  url: CONFIGURATION_EXPORT_YELLOW,
  method: 'POST',
  responseType: 'blob',
  data: {
    c4sCode,
    description,
    exportDate,
    ignoreList,
    name,
    planningPeriodRange,
    productModelName,
    project,
    selectedElements,
    validationStatus
  }
})

// POST: Export Windchill configuration
export const exportWindchillConfiguration = async ({
  c4sCode = '',
  createdDate = '',
  creator = '',
  description = '',
  id = '',
  ignoreList = [],
  invalidChoices = [],
  modifiedDate = '',
  name = '',
  planningPeriodRange = {},
  productModel = {},
  project = '',
  selectedChoices = []
} = {}) => pacHttpClient({
  url: CONFIGURATION_EXPORT_WINDCHILL,
  method: 'POST',
  responseType: 'json',
  data: {
    c4sCode,
    createdDate,
    creator,
    description,
    id,
    ignoreList,
    invalidChoices,
    modifiedDate,
    name,
    planningPeriodRange,
    productModel,
    project,
    selectedChoices
  }
})

// POST: Fill configuration with all valid choices
export const fillConfigurationAllValidChoices = async ({
  encodedBusinessName = '',
  planningPeriodRange = {},
  ruleIdsToIgnore = [],
  selectedChoices = []
} = {}) => pacHttpClient({
  url: CONFIGURATION_FILL_ALL_VALID_CHOICES,
  method: 'POST',
  data: {
    encodedBusinessName,
    planningPeriodRange,
    ruleIdsToIgnore,
    selectedChoices
  }
})

// POST: Fill configuration completely
export const fillConfigurationComplete = async ({
  encodedBusinessName = '',
  planningPeriodRange = {},
  ruleIdsToIgnore = [],
  selectedChoices = []
} = {}) => pacHttpClient({
  url: CONFIGURATION_FILL_COMPLETE,
  method: 'POST',
  data: {
    encodedBusinessName,
    planningPeriodRange,
    ruleIdsToIgnore,
    selectedChoices
  }
})

// POST: Fill configuration with single valid choices
export const fillConfigurationSingleValidChoices = async ({
  encodedBusinessName = '',
  planningPeriodRange = {},
  ruleIdsToIgnore = [],
  selectedChoices = []
} = {}) => pacHttpClient({
  url: CONFIGURATION_FILL_SINGLE_VALID_CHOICES,
  method: 'POST',
  data: {
    encodedBusinessName,
    planningPeriodRange,
    ruleIdsToIgnore,
    selectedChoices
  }
})

// GET: Fill configuration with single valid choices
export const fillConfigurationValidChoicesData = async choiceId => pacHttpClient({
  url: CONFIGURATION_FILL_SINGLE_VALID_CHOICES_DATA.replace('choice-id', choiceId),
  method: 'GET'
})

// GET: Fill configuration with single valid choices
export const fillConfigurationValidCompleteData = async correlationId => pacHttpClient({
  url: `${ CONFIGURATION_FILL_COMPLETE }/${ correlationId }`,
  method: 'GET'
})

// GET: Fill configuration with single valid choices
export const getConfigurationCombinationsData = async correlationId => pacHttpClient({
  url: CONFIGURATION_COMBINATIONS_V2_DATA.replace('correlation-id', correlationId),
  method: 'GET'
})

// POST: Update configuration
export const updateConfiguration = async ({
  c4sCode = '',
  createdDate = '',
  creator = '',
  description = '',
  id = '',
  ignoreList = [],
  invalidChoices = [],
  modifiedDate = '',
  name = '',
  planningPeriodRange = {},
  productModel = {},
  project = '',
  selectedChoices = [],
  authorizedUsers = []
} = {}) => pacHttpClient({
  url: CONFIGURATION_UPDATE,
  method: 'POST',
  data: {
    c4sCode,
    createdDate,
    creator,
    description,
    id,
    ignoreList,
    invalidChoices,
    modifiedDate,
    name,
    planningPeriodRange,
    productModel,
    project,
    selectedChoices,
    authorizedUsers
  }
})

// POST: Validate configuration
export const validateConfiguration = async ({
  encodedBusinessName = '',
  planningPeriodRange = {},
  ruleIdsToIgnore = [],
  selectedChoices = []
} = {}) => pacHttpClient({
  url: CONFIGURATION_VALIDATION,
  method: 'POST',
  data: {
    encodedBusinessName,
    planningPeriodRange,
    ruleIdsToIgnore,
    selectedChoices
  }
})

// POST: Async validate configuration
export const validateConfigurationV2 = async ({
  encodedBusinessName = '',
  planningPeriodRange = {},
  ruleIdsToIgnore = [],
  selectedChoices = []
} = {}) => pacHttpClient({
  url: CONFIGURATION_VALIDATION_V2,
  method: 'POST',
  data: {
    encodedBusinessName,
    planningPeriodRange,
    ruleIdsToIgnore,
    selectedChoices
  }
})

export const getValidateConfiguration = async (correlationId = '') => pacHttpClient({
  url: `${ CONFIGURATION_VALIDATION }/${ correlationId }`,
  method: 'GET'
})

// GET: Get windchill configuration specifications
export const getWindchillConfigSpecs = async () => pacHttpClient({
  url: CONFIGURATION_WINDCHILL_CONFIG_SPECS,
  method: 'GET'
})

// GET: Get windchill containers
export const getWindchillContainers = async () => pacHttpClient({
  url: CONFIGURATION_WINDCHILL_CONTAINERS,
  method: 'GET'
})

// GET: Get windchill option sets
export const getWindchillOptionSets = async () => pacHttpClient({
  url: CONFIGURATION_WINDCHILL_OPTION_SETS,
  method: 'GET'
})

// PUT: Create windchill variant specification - V2
export const createWindchillVariantSpecification = async ({
  configurationSpecificationStructure = {},
  configurationSpecificationOptionFilter = {},
  optionSetOid = '',
  targetContainerOid = '',
  variantSpecName = '',
  variantSpecDescription = '',
  targetFolder = '',
  filterMode = '',
  configurationId = '',
  optionsAndChoices = []
} = {}) => pacHttpClient({
  url: CONFIGURATION_EXPORT_WINDCHILL_VARIANT_SPEC,
  method: 'PUT',
  data: {
    configurationSpecificationStructure,
    configurationSpecificationOptionFilter,
    optionSetOid,
    targetContainerOid,
    variantSpecName,
    variantSpecDescription,
    targetFolder,
    filterMode,
    configurationId,
    optionsAndChoices
  }
})

// POST: Update windchill variant specification - V2
export const updateWindchillVariantSpecification = async ({
  configurationSpecificationStructure = {},
  configurationSpecificationOptionFilter = {},
  optionSetOid = '',
  targetContainerOid = '',
  variantSpecName = '',
  variantSpecDescription = '',
  targetFolder = '',
  filterMode = '',
  configurationId = '',
  optionsAndChoices = []
} = {}) => pacHttpClient({
  url: CONFIGURATION_EXPORT_WINDCHILL_VARIANT_SPEC,
  method: 'POST',
  data: {
    configurationSpecificationStructure,
    configurationSpecificationOptionFilter,
    optionSetOid,
    targetContainerOid,
    variantSpecName,
    variantSpecDescription,
    targetFolder,
    filterMode,
    configurationId,
    optionsAndChoices
  }
})

// GET: Update windchill variant specification / POLL
export const getWindchillVariantSpecification = async (correlationId = '') => pacHttpClient({
  url: `${ CONFIGURATION_EXPORT_WINDCHILL_VARIANT_SPEC_DATA }/${ correlationId }`,
  method: 'GET'
})

// POST: Create windchill filter - V2
export const createWindchillFilter = async ({
  configurationSpecificationStructure = {},
  configurationSpecificationOptionFilter = {},
  optionSetOid = '',
  filterName = '',
  configurationId = '',
  optionsAndChoices = [],
  filterOid = undefined
} = {}) => pacHttpClient({
  url: CONFIGURATION_EXPORT_WINDCHILL_FILTER,
  method: 'POST',
  data: {
    configurationSpecificationStructure,
    configurationSpecificationOptionFilter,
    optionSetOid,
    filterName,
    configurationId,
    optionsAndChoices,
    filterOid
  }
})

// GET: Export windchill variant specification / POLL
export const getCreateWindchillFilter = async (correlatonId = '') => pacHttpClient({
  url: `${ CONFIGURATION_EXPORT_WINDCHILL_FILTER_DATA }/${ correlatonId }`,
  method: 'GET'
})
