import LocalStorage from 'quasar/src/plugins/LocalStorage.js';
import getUserSession from '@/utils/getUserSession'

// Requests new token and updates it in LocalStorage
const refreshUserToken = async function () {
  const { token } = await getUserSession()

  LocalStorage.set(process.env.VUE_APP_STORAGE_KEY_TOKEN, token)

  return token
}

// Request success handling
export async function requestSuccess(config = {}) {
  const token = await refreshUserToken()
  const newConfig = { ...config }

  newConfig.headers = {
    ...config.headers || {},
    Authorization: `Bearer ${ token }`
  }

  return newConfig
}

// Request error handling
export function requestError(error = {}) {
  return Promise.reject(error)
}
