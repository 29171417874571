import { ASYNC_REQUEST_STATUS, ERRORS } from '@/constants'

function waitFor(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

function setTimeoutByRequestCounter(num, timeout = 10000, minValue = 5, maxValue = 30, interval = 2000) {
  if (num < minValue && timeout === interval) return timeout
  if (num < minValue) return minValue * interval
  if (num > maxValue) return maxValue * interval
  return num * interval
}

function getErrorMessage(errorCode, errorMessage) {
  const customError = ERRORS[errorCode] || errorMessage
  if (customError) {
    return customError
  }
  return 'Async request failed'
}

export async function* fetchAsync({
  correlationId,
  immediate = false,
  timeout = 10000,
  asyncEndpoint,
  ignoreStatus = []
}) {
  try {
    await waitFor(immediate ? 0 : timeout)
    const response = await asyncEndpoint(correlationId)
    if (response.status === ASYNC_REQUEST_STATUS.FAILED) {
      const errorMessage = getErrorMessage(response.errorCode, response.errorMessage)
      throw new Error(errorMessage)
    }
    if (response.status === ASYNC_REQUEST_STATUS.IN_PROGRESS) yield response
    return response
  }
  catch (e) {
    if (ignoreStatus.includes(e.status) || ignoreStatus.includes(e.response?.status)) {
      yield 'Error allowed: fetching again'
    }
    throw e
  }
}

export function poll() {
  let stop = false

  async function executePoll({
    correlationIdEndpoint,
    asyncEndpoint,
    immediate = true,
    ignoreStatus = [],
    currentCorrelationId = '',
    timeout = 10000,
    counter = 0,
    ignorePolling = false
  }) {
    const { getCorrelationId, params } = correlationIdEndpoint

    const correlationIdresponse = currentCorrelationId || await getCorrelationId(params || null)
    const correlationId = correlationIdresponse?.correlationId || correlationIdresponse

    if (stop || ignorePolling) return { status: ASYNC_REQUEST_STATUS.STOPPED, correlationId }

    const response = fetchAsync({ correlationId, immediate, asyncEndpoint, ignoreStatus, timeout })
    const { done, value } = await response.next()

    if (!done && !stop) {
      return executePoll({
        correlationIdEndpoint,
        asyncEndpoint,
        immediate: false,
        ignoreStatus,
        currentCorrelationId: { correlationId },
        timeout: setTimeoutByRequestCounter(counter, timeout),
        counter: counter + 1
      })
    }

    return {
      ...value,
      correlationId,
      status: stop ? ASYNC_REQUEST_STATUS.STOPPED : ASYNC_REQUEST_STATUS.SUCCESS
    }
  }

  function clearPoll() {
    stop = true
  }

  return { executePoll, clearPoll }
}
