import { pmmHttpClient } from '@/api/instances'
import {
  COMPONENT,
  COMPONENT_HIGH_GROUP,
  COMPONENT_HIGH_GROUP_LIST,
  COMPONENT_LIST,
  COMPONENT_OPTIONS,
  COMPONENT_SEARCH,
  COMPONENT_VARIANT_OPTIONS,
  COMPONENT_VARIANT_SEARCH
} from './endpoints'

// GET: Component
export const getComponent = async ({ id = '', pmType = '', pmIdentifier = '' } = {}) => pmmHttpClient({
  url: COMPONENT,
  method: 'GET',
  params: {
    id,
    pmType,
    pmIdentifier
  }
})

// GET: Component options
export const getComponentOptions = async ({
  pmIdentifier = '',
  pmType = '',
  ids = '',
  fromPeriod = '',
  toPeriod = ''
} = {}) => pmmHttpClient({
  url: COMPONENT_OPTIONS,
  method: 'GET',
  params: {
    pmIdentifier,
    pmType,
    ids,
    fromPeriod,
    toPeriod
  }
})

// GET: Search component
export const searchComponents = async ({
  pmType = '',
  pmIdentifier = '',
  numberOfElements = -1,
  query = ''
} = {}) => pmmHttpClient({
  url: COMPONENT_SEARCH,
  method: 'GET',
  params: {
    pmType,
    pmIdentifier,
    numberOfElements,
    query
  }
})

// GET: Components
export const getComponents = async (pmEncodedBusinessName = '') => pmmHttpClient({
  url: COMPONENT_LIST,
  method: 'GET',
  params: { pmEncodedBusinessName }
})

// GET: Components by high group component
export const getComponentsByHighGroupComponent = async ({ id = '', pmEncodedBusinessName = '' } = {}) => pmmHttpClient({
  url: COMPONENT_HIGH_GROUP,
  method: 'GET',
  params: {
    id,
    pmEncodedBusinessName
  }
})

// GET: High group components
export const getHighGroupComponents = async (pmEncodedBusinessName = '') => pmmHttpClient({
  url: COMPONENT_HIGH_GROUP_LIST,
  method: 'GET',
  params: { pmEncodedBusinessName }
})

// GET: Component variant options
export const getComponentVariantOptions = async ({
  id = '',
  pmEncodedBusinessName = '',
  fromPeriod = '',
  toPeriod = ''
} = {}) => pmmHttpClient({
  url: COMPONENT_VARIANT_OPTIONS,
  method: 'GET',
  params: {
    id,
    pmEncodedBusinessName,
    fromPeriod,
    toPeriod
  }
})

// GET: Search component variant
export const searchComponentVariant = async ({
  pmType = '',
  pmIdentifier = '',
  numberOfElements = -1,
  query = ''
} = {}) => pmmHttpClient({
  url: COMPONENT_VARIANT_SEARCH,
  method: 'GET',
  params: {
    pmType,
    pmIdentifier,
    numberOfElements,
    query
  }
})
