import { vmHttpClient } from '@/api/instances'
import {
  AUTO_FIX_COLLECTION,
  SCHEDULE_COLLECTIONS,
  SCHEDULE_DETAILS,
  SCHEDULE_RESULTS,
  SCHEDULES, UPDATE_SCHEDULE,
  GET_AND_SAVE_COLLECTION_PATH,
  GET_SCHEDULE_EXECUTION_LIST, SAVE_TO_SUMMARY
} from './endpoints'

export const getSchedules = async () => vmHttpClient({
  url: SCHEDULES,
  method: 'GET'
})

export const createSchedule = async ({
  name = '',
  department = '',
  nextScheduledDate = '',
  encodedBusinessName = '',
  planningPeriod = {}
} = {}) => vmHttpClient({
  url: SCHEDULES,
  method: 'POST',
  data: {
    name,
    department,
    encodedBusinessName,
    planningPeriod,
    nextScheduledDate
  }
})

export const updateSchedule = async ({
  name = '',
  department = '',
  nextScheduledDate = '',
  encodedBusinessName = '',
  planningPeriod = {},
  scheduleId = ''
} = {}) => vmHttpClient({
  url: UPDATE_SCHEDULE.replace(':scheduleId', scheduleId),
  method: 'PUT',
  data: {
    name,
    department,
    encodedBusinessName,
    planningPeriod,
    nextScheduledDate
  }
})

export const getScheduleDetails = async (scheduleId = '') => vmHttpClient({
  url: SCHEDULE_DETAILS.replace(':scheduleId', scheduleId),
  method: 'GET'
})

export const getScheduleCollectionsList = async (scheduleId = '', queryParams = '') => vmHttpClient({
  url: SCHEDULE_COLLECTIONS.replace(':scheduleId', scheduleId),
  method: 'GET',
  params: new URLSearchParams(queryParams)
})

export const getScheduleCollectionsResultsList = async (scheduleId = '', queryParams = '') => vmHttpClient({
  url: SCHEDULE_RESULTS.replace(':scheduleId', scheduleId),
  method: 'GET',
  params: new URLSearchParams(queryParams)
})

export const getScheduleExecutionList = async (scheduleId = '') => vmHttpClient({
  url: GET_SCHEDULE_EXECUTION_LIST.replace(':scheduleId', scheduleId),
  method: 'GET'
})

export const createCollection = async ({
  scheduleId = '',
  collectionName = '',
  options = [],
  components = []
} = {}) => vmHttpClient({
  url: SCHEDULE_COLLECTIONS.replace(':scheduleId', scheduleId),
  method: 'POST',
  data: {
    collectionName,
    options,
    components
  }
})

export const autoFixCollection = async ({
  scheduleId = '',
  collectionId = ''
} = {}) => vmHttpClient({
  url: AUTO_FIX_COLLECTION.replace(':scheduleId', scheduleId).replace(':collectionId', collectionId),
  method: 'GET'
})

export const getScheduleCollections = async ({
  scheduleId = '',
  collectionId = ''
} = {}) => vmHttpClient({
  url: GET_AND_SAVE_COLLECTION_PATH.replace(':scheduleId', scheduleId).replace(':collectionId', collectionId),
  method: 'GET'
})

export const saveEditedCollection = async ({
  scheduleId = '',
  collectionId = '',
  collectionName = '',
  options = [],
  components = []
} = {}) => vmHttpClient({
  url: GET_AND_SAVE_COLLECTION_PATH.replace(':scheduleId', scheduleId).replace(':collectionId', collectionId),
  method: 'PUT',
  data: {
    collectionName,
    options,
    components
  }
})

// POST: Save to summary
export const saveToSummary = async (scheduleId = '', collectionId = '') => vmHttpClient({
  url: SAVE_TO_SUMMARY.replace(':scheduleId', scheduleId).replace(':collectionId', collectionId),
  method: 'POST'
})
