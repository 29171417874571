import notify from '@/utils/notify'
import errorMessages from '@/api/errors-handler/errorMessages'

export default function showHTTPErrorNotification(
  { status = '', message = '' } = {}
) {
  const errorExplanation = message || errorMessages[status] || ''
  return notify({
    title: `HTTP Error ${ status }`,
    content: errorExplanation,
    type: 'negative'
  })
}
