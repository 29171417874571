import { pmmHttpClient } from '@/api/instances'
import { OPTION, OPTION_CATEGORY, OPTION_CATEGORY_LIST, OPTION_SEARCH } from './endpoints'

// GET: Option
export const getOption = async ({ id = '', pmIdentifier = '', pmType = ' ' } = {}) => pmmHttpClient({
  url: OPTION,
  method: 'GET',
  params: {
    id,
    pmIdentifier,
    pmType
  }
})

// GET: Search Options
export const searchOptions = async ({
  pmType = '',
  pmIdentifier = '',
  numberOfElements = -1,
  query = ''
} = {}) => pmmHttpClient({
  url: OPTION_SEARCH,
  method: 'GET',
  params: {
    pmType,
    pmIdentifier,
    numberOfElements,
    query
  }
})

// GET: Option category
export const getOptionCategory = async ({ id = '', pmEncodedBusinessName = '' } = {}) => pmmHttpClient({
  url: OPTION_CATEGORY,
  method: 'GET',
  params: {
    id,
    pmEncodedBusinessName
  }
})

// GET: Option categories
export const getOptionCategories = async (pmEncodedBusinessName = '') => pmmHttpClient({
  url: OPTION_CATEGORY_LIST,
  method: 'GET',
  params: { pmEncodedBusinessName }
})
